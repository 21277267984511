import { ErrorViewProps, FormControlData, FormControlValue } from "../model";
import { For, Show, createEffect, splitProps } from "solid-js";
import { createFormControl } from "solid-forms";
import { animate } from "motion";

export function Error<V extends FormControlValue, D extends FormControlData>(props: ErrorViewProps<V, D>) {
  const [local, others] = splitProps(props, ["class"]);
  const control = props.control;
  createEffect(() => {
    // console.log(local.class);
  });
  const _control = Array.isArray(control) ? createFormControl<V, D>(...control) : control;
  let div_ref = null;
  createEffect(() => {
    if (!_control.isValid) {
      // animate(
      // 	div_ref,
      // 	{
      // 		x: [100, 0],
      // 	},
      // 	{ duration: 0.5, easing: "ease-in-out" },
      // );
    }
  });
  return (
    <Show when={!_control.isValid}>
      <div {...props} ref={div_ref} class={local?.class}>
        <For each={Object.keys(_control.errors)}>{(errorMsg: string) => <p>{errorMsg}</p>}</For>
      </div>
    </Show>
  );
}
